import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout/layout"
import { Link, navigate } from "gatsby"
import CtaButton from "../components/cta-button/cta-button"
import { faPhoneSquareAlt } from "@fortawesome/free-solid-svg-icons"
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./dead-ends.scss"
import { ServiceCard } from "../components/service-card/service-card"
import { SloganFlipper } from "../components/slogan/slogan-flipper"
import { GlobalStateProvider } from "../utils/globalStateProvider"
import { FullScreenModal } from "../components/full-screen-modal/full-screen-modal"

export default function NegligenciaSaberAbogadoActuaCorrectamente() {
  return (
    <GlobalStateProvider>
      <SEO title="Saber abogado actua correctamente" />
      <Layout pageId="dead-end-howto-layer-did-well">
        <div className="deadend-container">
          <h1>Resultado del cuestionario</h1>
          <h2>Aún puedes saber si tu abogado ha actuado correctamente</h2>
          <p>
            Saber si tu abogado ha actuado o no correctamente{" "}
            <Link to="/situacion-actual-reclamaciones-contra-abogados/">
              no siempre es un camino fácil.
            </Link>
            . Desde <strong>negligenciasdeabogados.net</strong>, uno de nuestros
            expertos te puede ayudar a saberlo con una simple consulta
            telefónica:
          </p>
          <center className="button-container">
            <ServiceCard
              title="Te llamamos 30"
              key="consulta-experto"
              featuredImage={
                <FontAwesomeIcon icon={faWhatsapp} color="#fcc831" size="2x" />
              }
              featureList={[
                "Servicio <strong>premium</strong>",
                "Un <strong>experto</strong> te llamará (tfno/whatsapp)",
                // "<strong>30 minutos</strong> por <strong class='price'>30€</strong>",
              ]}
              ctaLink="/consulta-experto"
            />
          </center>

          <p>
            Si consideras que hay que corregir algo en las respuestas del
            cuestionario de autovaloración,{" "}
            <Link to="/cuestionario-autovaloracion/">
              puedes probar de nuevo
            </Link>
          </p>

          <SloganFlipper></SloganFlipper>
        </div>
        <FullScreenModal />
      </Layout>
    </GlobalStateProvider>
  )
}
